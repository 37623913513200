<template>
    <div name="innertopbar" class="flex top-bar">
      <div class="breadcrumb">
        <router-link to="/">
          <i class="fas fa-home opacity-75"></i>
        </router-link>
        <i class="fas fa-caret-right carret"></i>
         <slot name="breadcrumb"> </slot>
      </div>

      <div class="text-right w-2/3">
        <ul class="mt-1 md:w-11/12 md:ml-6 inline-block">
        
          <slot name="listitems"></slot>
          <li class="inline-block mx-4 text-2xl text-gray-400">|</li>

          <li class="inline-block"  @click="$emit('close')">
            <i class="fas fa-times close-icon" title="Close"></i>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>

export default {
  name: "innertopbar",

}
</script>